import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./elixir/views/ElixirSale.css";
import { Description } from "./elixir/components/description";
import { Footer } from "./elixir/components/footer";

const App: React.FC = () => {
  const presaleState = React.useState(true);

  return (
    <div className="elixir-content">
      <div id="elixir-shop">
        <div />

        <button disabled={true} title="Purchase" />
      </div>

      <Description state={presaleState} permanent={true}>
        <p>
          All <b>Elixir</b> for <a href="https://everseed.com">Everseed</a> has
          been sold. If you have an <b>Elixir</b>, you can customize your own{" "}
          <b>Ranger Avatar</b> at the{" "}
          <a href="https://avatars.everseed.com">Everseed Avatar Creator</a>{" "}
          site starting on April 22. Avatar Birth (minting) will happen on April
          26.
          <br />
          <br />
          If you don’t have an <b>Elixir</b>, you can still tinker with the
          avatar customization options! However, you will not be able to mint
          the result.
        </p>
      </Description>

      <Footer />
    </div>
  );
};

ReactDOM.render(<App />, document.querySelector("#app"));
